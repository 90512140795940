import React from 'react';
import { Input, InputLeftElement, InputGroup, Box } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

export interface SearchProps {
  query: string;
  onInput: any;
}

const Search: React.FC<SearchProps> = ({ query, onInput }) => (
  <Box p={10} py={5}>
    <InputGroup>
      <InputLeftElement
        pointerEvents='none'
        children={<SearchIcon color='gray.300' />}
      />
      <Input type="text" name="search" value={query} onInput={onInput} />
    </InputGroup>
  </Box>
);

export default Search;
