import React from 'react';
import { Button } from '@chakra-ui/react';

export interface ActionButtonProps<Item extends {}> {
  creator: (item: Item) => () => void;
  text?: string;
  size: string;
  item: Item;
}

const ActionButton = <T extends {}>({
  size,
  item,
  text,
  creator,
}: ActionButtonProps<T>): React.ReactElement<ActionButtonProps<T>> => (
  <Button
    size={size || 'sm'}
    color="purple"
    bg="aquamarine"
    fontWeight="bold"
    _hover={{ bg: 'aquamarine' }}
    onClick={creator(item)}
  >
    {text || 'Trade'}
  </Button>
);

export default ActionButton;
