import React from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { useTable, useSortBy, Column } from 'react-table';

export type DataTableProps<Data extends object> = {
  data: Data[];
  columns: Column<Data>[];
};

export function DataTable<Data extends object>({
  data,
  columns,
}: DataTableProps<Data>) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <Table {...getTableProps()}>
      <Thead>
        {headerGroups.map((headerGroup, index) => (
          <Tr {...headerGroup.getHeaderGroupProps()} key={`row-${index + 1}`}>
            {headerGroup.headers.map((column, index) => (
              <Th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                isNumeric={column.isNumeric}
                key={`head-${index + 1}`}
                {...(index === 0
                  ? {
                      left: 0,
                      bg: 'white',
                      zIndex: 'base',
                      position: 'sticky',
                    }
                  : {})}
              >
                {column.render('Header')}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);

          return (
            <Tr {...row.getRowProps()} key={`row-${index + 1}`}>
              {row.cells.map((cell, index) => {
                if (index === 0) {
                  return (
                    <Th
                      {...cell.getCellProps()}
                      isNumeric={cell.column.isNumeric}
                      left={0}
                      bg="white"
                      zIndex="base"
                      position="sticky"
                    >
                      {cell.render('Cell')}
                    </Th>
                  );
                }

                if (index === row.cells.length - 1) {
                  return (
                    <Td
                      {...cell.getCellProps()}
                      bg="white"
                      right={0}
                      zIndex="base"
                      position="sticky"
                    >
                      {cell.render('Cell')}
                    </Td>
                  );
                }

                return (
                  <Td
                    {...cell.getCellProps()}
                    isNumeric={cell.column.isNumeric}
                  >
                    {cell.render('Cell')}
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}

export default DataTable;
