import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Tag,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Column } from 'react-table';
import { useMessageBird, useSearch } from '../../hooks';
import { OnlineFund } from '../../types';
import DataTable from '../data-table';
import DataList from '../data-list';
import ActionButton from '../action-button';
import Search from '../search';

type Rate = {
  buy: string;
  name: ReactElement;
  range: string;
  action: ReactElement;
  utilityMethods: ReactElement;
};

const columns: Column<Rate>[] = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Utility Methods',
    accessor: 'utilityMethods',
  },
  {
    Header: 'Range',
    accessor: 'range',
  },
  {
    Header: 'Buy',
    accessor: 'buy',
  },
  {
    Header: '',
    accessor: 'action',
  },
];

export interface OnlineFundsProps {
  funds: OnlineFund[];
}

export interface DataListProps extends Rate {}

const OnlineFundsID: React.FC<{
  name: string;
  icon?: string;
  description: string;
}> = ({ name, description, icon }) => {
  const [iconSRC, setIconSRC] = useState<string | false>(false);

  useEffect(() => {
    (async () => {
      try {
        const { default: iconSRC } = await import(
          `../../icons/funds/${icon}.svg`
        );
        setIconSRC(iconSRC);
      } catch {
        setIconSRC(false);
      }
    })();
  }, [icon]);

  return (
    <VStack align="left" maxWidth="180px">
      <HStack>
        {iconSRC && <Image src={iconSRC} alt={name} width={30} />}
        <Heading size="sm" textTransform="capitalize">
          {name}
        </Heading>
      </HStack>
      {description && (
        <Text fontWeight={400} fontSize="sm" mt={10} lineHeight={1.5}>
          {description.trim()}
        </Text>
      )}
    </VStack>
  );
};

const UtilityMethods: React.FC<{ variants: OnlineFund['variants'] }> = ({
  variants,
}) => {
  const methods = [
    ...new Set(variants.map((variant) => variant.utility_method)),
  ]
    .map((value) => value.split(',').map((v) => v.trim()))
    .flat(Infinity)
    .filter(Boolean);

  return (
    <Flex>
      {methods.map((method, index) => (
        <Tag
          mr={1}
          key={String(`${method}-${index}`)}
          textTransform="capitalize"
        >
          {method}
        </Tag>
      ))}
    </Flex>
  );
};

const getRange = (variants: OnlineFund['variants']) => {
  if (variants.length < 1) return {};

  const lastIndex = variants.length - 1;
  const { 0: first, [lastIndex]: last } = variants;

  const [min] = first.range.split('-');
  const [max] = last.range.split('-');

  const denomination =
    // eslint-disable-next-line no-nested-ternary
    min === max ? (min ? `$${min}` : '') : `$${min} - $${max}`;
  const buy =
    first.buy === last.buy
      ? `${first.buy}/$`
      : `${first.buy}/$ - ${last.buy}/$`;

  return {
    denomination,
    buy,
  };
};

const DataListHead: React.FC<DataListProps> = ({ name }) => <>{name}</>;

const DataListBody: React.FC<DataListProps> = ({
  utilityMethods,
  action,
  buy,
  range,
}) => (
  <VStack align="left" sx={{ marginTop: '20px !important' }} spacing={5}>
    <VStack align="left" spacing={2}>
      {utilityMethods}
    </VStack>

    <Grid gap={{ md: 10, sm: 5 }} templateColumns="1fr 1fr">
      <VStack as={GridItem} align="left" spacing={2}>
        <Heading size="sm" fontWeight={600}>
          Range
        </Heading>
        <Text>{range}</Text>
      </VStack>

      <VStack as={GridItem} align="left" spacing={2}>
        <Heading size="sm" fontWeight={600}>
          Buy
        </Heading>
        <Text>{buy}</Text>
      </VStack>
    </Grid>

    <Box>{action}</Box>
  </VStack>
);

const OnlineFunds: React.FC<OnlineFundsProps> = ({ funds }) => {
  const { query, results, handleOnInput } = useSearch<OnlineFund>(funds, [
    'name',
    'description',
    'variants.utility_method',
  ]);
  const messageBird = useMessageBird();
  const isMediumScreen = useBreakpointValue({
    md: true,
    lg: false,
    base: true,
  });

  const createAction = (item: OnlineFund) =>
    useCallback(() => {
      const message = `Hello, I want to trade some ${item.name}.`;
      messageBird().startConversation(message);
    }, [messageBird]);

  const format = useCallback<(funds: OnlineFund[]) => Rate[]>(
    (funds) =>
      funds
        .sort((a, b) => b.variants.length - a.variants.length)
        .map((fund) => {
          const { variants, name, icon, description } = fund;
          const action = (
            <ActionButton<OnlineFund>
              creator={createAction}
              item={fund}
              size="md"
            />
          );
          const utilityMethods = <UtilityMethods variants={variants} />;
          const { buy = '-', denomination: range = '-' } = getRange(variants);
          const fundsName = (
            <OnlineFundsID name={name} icon={icon} description={description} />
          );

          return { name: fundsName, action, utilityMethods, range, buy };
        }),
    [],
  );

  const data = format(query ? results : funds);

  return (
    <Box>
      <Search query={query} onInput={handleOnInput} />

      {isMediumScreen && (
        <DataList<DataListProps>
          data={data}
          Head={DataListHead}
          Body={DataListBody}
        />
      )}

      {!isMediumScreen && <DataTable columns={columns} data={data} />}
    </Box>
  );
};

export default OnlineFunds;
