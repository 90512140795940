import React from 'react';
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Box,
  useBreakpointValue,
} from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import Crypto from './crypto';
import GiftCards from './giftcards';
import OnlineFunds from './online-funds';
import { Crypto as CryptoInterface, OnlineFund, Giftcard } from '../../types';

export interface StaticQueryType {
  allCrypto: {
    nodes: CryptoInterface[];
  };
  allOnlineFund: {
    nodes: OnlineFund[];
  };
  allGiftcard: {
    nodes: Giftcard[];
  };
}

const Rates: React.FC = () => {
  const { allCrypto, allOnlineFund, allGiftcard } =
    useStaticQuery<StaticQueryType>(graphql`
      query RatesQuery {
        allCrypto {
          nodes {
            denominations {
              range
              buy
            }
            id
            name
            shortname
          }
        }
        allOnlineFund {
          nodes {
            name
            icon
            description
            variants {
              utility_method
              range
              buy
            }
            id
          }
        }
        allGiftcard {
          nodes {
            name
            icon
            denominations {
              buy
              country
              range
              form
              receipt
            }
            priority
            minimum
            description
            id
          }
        }
      }
    `);

  const isMediumScreen = useBreakpointValue({
    md: true,
    lg: false,
    base: true,
  });

  const filteredFunds = allOnlineFund.nodes.filter(
    (fund) => fund.variants.length > 0 && fund.name,
  );

  return (
    <Box
      px={{ md: 10, sm: 0, base: 0 }}
      margin="0 auto"
      width="100%"
      borderRadius="md"
    >
      <Tabs
        py={5}
        sx={{
          '.chakra-tabs__tablist': {
            width: '500px',
            maxWidth: '100%',
          },
          '.chakra-tabs__tab-panels': {},
        }}
      >
        <TabList
          margin="0 auto"
          color="purple"
          borderWidth="1px"
          display="flex"
          align="center"
          textAlign="center"
          border="none"
          position="sticky"
          top={0}
        >
          {['Crypto', 'Funds', 'Gift Cards'].map((tab) => (
            <Tab
              py={5}
              px={{ base: 2 }}
              key={tab}
              width="33.333%"
              _selected={{
                bg: 'white',
                color: 'purple',
                fontWeight: 'bold',
                borderTopRadius: 'md',
              }}
            >
              {tab}
            </Tab>
          ))}
        </TabList>
        <TabPanels
          py={5}
          pb={10}
          bg="white"
          borderRadius="md"
          px={`${isMediumScreen ? 0 : 5} !important`}
        >
          <TabPanel p={0} maxW="100%" overflowX="auto">
            <Crypto crypto={allCrypto.nodes} />
          </TabPanel>
          <TabPanel p={0} maxW="100%" overflowX="auto">
            <OnlineFunds funds={filteredFunds} />
          </TabPanel>
          <TabPanel p={0} maxW="100%" overflowX="auto">
            <GiftCards giftcards={allGiftcard.nodes} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Rates;
