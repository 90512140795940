import React, { useCallback, useState } from 'react';
import {
  VStack,
  FormControl,
  Input,
  Button,
  Text,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  InputRightAddon,
  Flex,
} from '@chakra-ui/react';

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Crypto as CryptoInterface } from '../../types';
import { cleanDenominations } from '../../utils';
import { useMessageBird } from '../../hooks';
import LivePrice from '../live-price';

export interface CryptoProps {
  crypto: CryptoInterface[];
}

const Crypto: React.FC<CryptoProps> = ({ crypto }) => {
  const messageBird = useMessageBird();
  const [value, setValue] = useState<number>(0);
  const [currency, setCurrency] = useState<string>(crypto[0].shortname);
  const [inputError, setInputError] = useState<string>(undefined as any);
  const [result, setResult] = useState<string>(undefined as any);

  const convert = useCallback(
    (units: number, currency: string) => {
      const c = crypto.find((c) => c.shortname === currency);

      const denominations =
        cleanDenominations(
          c?.denominations as CryptoInterface['denominations'],
        ) || [];

      let d = denominations.find(
        (denomination) =>
          units >= denomination.min && units <= denomination.max,
      );

      if (!d) {
        d = denominations.find((denomination) => units < denomination.min);
      }

      const costPerUnit: number = parseInt(d?.buy || '0', 10);
      const value = costPerUnit * units;

      return value;
    },
    [crypto],
  );

  const getCurrency = useCallback(
    (currency: string) => crypto.find((c) => c.shortname === currency),
    [crypto],
  );

  const isBelowMin = useCallback(
    (value: number) => {
      const { min } = cleanDenominations(
        getCurrency(currency)
          ?.denominations as CryptoInterface['denominations'],
      )[0];

      return { check: value > 0 && value < min, min };
    },
    [currency, crypto, getCurrency],
  );

  const handleInputChange = useCallback(
    (event) => {
      const value = parseInt(event.target.value, 10) || 0;

      setValue(value);

      const amount = convert(value, currency);
      const isBelow = isBelowMin(value);

      setInputError(
        isBelow.check
          ? `Please enter an amount from $${isBelow.min}`
          : (undefined as any),
      );

      setResult(amount.toString());
    },
    [convert, currency, setResult, isBelowMin, setValue],
  );

  const handleTradeNow = useCallback(() => {
    if (!currency || isBelowMin(value).check) return;

    const { name } = getCurrency(currency) ?? {};
    const message = `I want to trade $${value} of ${name}.`;

    messageBird()?.startConversation(message);
  }, [messageBird, currency, crypto, value, isBelowMin, getCurrency]);

  const handleMenuClick = (event: any) => {
    if (event.target.tagName === 'BUTTON') {
      setCurrency(event.target.dataset?.currency);
    }
  };

  return (
    <VStack width="100%" as="form" spacing={3} justify="start">
      <FormControl position="relative" id="fromSelector">
        {inputError && (
          <Text color="crimson" fontWeight="bold" fontSize="sm">
            {inputError}
          </Text>
        )}

        <InputGroup my={1} height="60px">
          <InputLeftElement
            height="60px"
            display="grid"
            placeItems="center"
            children={<Text fontSize="xl">&#36;</Text>}
          />
          <Input
            required
            size="xl"
            fontSize="2xl"
            type="number"
            placeholder="0"
            borderRadius="md"
            name="fromValue"
            onChange={handleInputChange}
            sx={{ paddingRight: '10px' }}
            isInvalid={Boolean(inputError)}
          />

          <InputRightAddon
            height="60px"
            children={
              <Menu>
                {({ isOpen }) => (
                  <>
                    <MenuButton
                      size="sm"
                      as={Button}
                      variant="unstyled"
                      textTransform="uppercase"
                      isActive={isOpen}
                      rightIcon={
                        isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />
                      }
                    >
                      {currency}
                    </MenuButton>
                    <MenuList
                      maxHeight="300px"
                      overflowY="scroll"
                      onClick={handleMenuClick}
                    >
                      {crypto.map((currency) => (
                        <MenuItem
                          key={currency.id}
                          textTransform="capitalize"
                          data-currency={currency.shortname}
                        >
                          {currency.shortname}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </>
                )}
              </Menu>
            }
          />
        </InputGroup>

        <LivePrice symbol={currency} />
      </FormControl>

      <FormControl position="relative" id="toSelector">
        <InputGroup my={1} height="60px">
          <InputLeftElement
            height="60px"
            display="grid"
            placeItems="center"
            children={<Text fontSize="xl">&#8358;</Text>}
          />
          <Input
            required
            readonly
            size="xl"
            type="number"
            name="toValue"
            fontSize="2xl"
            value={result}
            borderRadius="md"
            borderRight="none"
          />

          <InputRightAddon
            height="60px"
            borderLeft="none"
            bgColor="transparent"
            children={<Text fontWeight="medium">NGN</Text>}
          />
        </InputGroup>
      </FormControl>

      <Flex justifyContent="flex-end" width="100%">
        <Button
          color="purple"
          bg="aquamarine"
          alignSelf="start"
          borderColor="aquamarine"
          onClick={handleTradeNow}
          _hover={{ textDecoration: 'none' }}
        >
          Trade Now
        </Button>
      </Flex>
    </VStack>
  );
};

export default React.memo(Crypto);
