import React from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import Crypto from './crypto';
import OnlineFunds from './online-funds';
import { Crypto as CryptoInterface, OnlineFund } from '../../types';

export interface CryptoProps {}

export interface StaticQueryType {
  allCrypto: {
    nodes: CryptoInterface[];
  };
  allOnlineFund: {
    nodes: OnlineFund[];
  };
}

const Converter: React.FC<CryptoProps> = () => {
  const { allCrypto, allOnlineFund } = useStaticQuery<StaticQueryType>(graphql`
    query ConverterQuery {
      allCrypto {
        nodes {
          denominations {
            range
            buy
          }
          id
          name
          shortname
        }
      }
      allOnlineFund {
        nodes {
          name
          variants {
            utility_method
            range
            buy
          }
          id
        }
      }
    }
  `);

  return (
    <Tabs>
      <TabList
        color="purple"
        borderWidth="1px"
        borderTopRightRadius="md"
        borderTopLeftRadius="md"
        display="flex"
        align="left"
        textAlign="left"
        border="none"
      >
        {['Crypto', 'Online Funds'].map((tab, i) => (
          <Tab
            p={5}
            key={tab}
            width="50%"
            borderTopLeftRadius={i === 0 ? 'md' : 0}
            borderTopRightRadius={i === 1 ? 'md' : 0}
            _selected={{
              color: 'purple',
              bg: '#f3f3f3',
              fontWeight: 'bold',
              borderTopLeftRadius: i === 0 ? 'md' : 0,
              borderTopRightRadius: i === 1 ? 'md' : 0,
            }}
          >
            {tab}
          </Tab>
        ))}
      </TabList>
      <TabPanels color="#000" mt={8} py={5} px={10} pb={10} minHeight="280px">
        <TabPanel p={0}>
          <Crypto crypto={allCrypto.nodes} />
        </TabPanel>
        <TabPanel p={0}>
          <OnlineFunds funds={allOnlineFund.nodes} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default Converter;
