import {
  VStack,
  Heading,
  Box,
  Text,
  Link as ChakraLink,
  Flex,
  chakra,
  Button,
  ChakraComponent,
} from '@chakra-ui/react';
import { graphql, Link, PageProps } from 'gatsby';
import React, { useCallback, useRef } from 'react';
import { ArrowDownIcon } from '@chakra-ui/icons';

import Layout from '../layout';
import CurrenciesFAQ from '../components/faqs/currencies';
import Converter from '../components/converter';
import Head from '../components/head';
import Rates from '../components/rates';
import { Crypto } from '../types';

export interface DataType {
  allCrypto: {
    nodes: Crypto[];
  };
}

const Currencies: React.FC<PageProps<DataType>> = () => {
  const ratesRef = useRef<HTMLDivElement>(null);

  const handleViewRatesClick = useCallback(() => {
    if (typeof window === 'undefined') return;
    ratesRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [ratesRef.current]);

  return (
    <Layout headerColorScheme="dark">
      <Head title="Crypto, Digital Funds, Giftcards - Jamestown" />

      <Flex
        color="white"
        py="3rem"
        px="calc(var(--cp) + 1rem)"
        minHeight={{ md: '600px', base: '800px' }}
        position="relative"
        spacing={10}
        display="grid"
        placeItems="center"
        sx={{
          ':before': {
            top: 0,
            left: 0,
            bg: 'purple',
            width: '100%',
            height: '100%',
            content: '" "',
            position: 'absolute',
            clipPath: {
              md: 'polygon(0 0, 100% 0%, 100% 30%, 0 100%)',
              base: 'polygon(0 0, 100% 0%, 100% 50%, 0 100%)',
            },
          },
        }}
      >
        <Flex
          w="100%"
          px="calc(var(--cp) + 1rem)"
          position="absolute"
          flexDir={{ md: 'row', base: 'column' }}
          justifyContent="space-between"
        >
          <VStack spacing={5} w={500} maxW="100%" mr={{ md: 10, base: 0 }}>
            <Heading size="2xl" color="white">
              Convert digital currencies with ease at{' '}
              <chakra.span color="aquamarine">Jamestown</chakra.span>.
            </Heading>

            <Text fontWeight={500}>
              Fast, reliable and smart trades should dig deep into your problems
              and not your pockets or busy schedule.
            </Text>

            <Flex w="100%" justifyContent="start">
              <Button
                color="purple"
                bg="aquamarine"
                alignSelf="start"
                borderColor="aquamarine"
                rightIcon={<ArrowDownIcon />}
                onClick={handleViewRatesClick}
                _hover={{ textDecoration: 'none' }}
              >
                See Rates
              </Button>
            </Flex>
          </VStack>

          <Box
            mt={{ md: 0, base: 10 }}
            mr={{ md: 10, base: 0 }}
            borderRadius="lg"
            boxShadow="sm"
            bg="white"
            maxW="100%"
            w="480px"
            position="relative"
            sx={{
              ':before': {
                // content: '" "',
                width: '100px',
                height: '100px',
                background: 'aquamarine',
                position: 'absolute',
                borderRadius: '50%',
                right: -10,
                top: -10,
                zIndex: 0,
              },
            }}
          >
            <Converter />
          </Box>
        </Flex>
      </Flex>

      <Box ref={ratesRef} my={{ md: 20, base: 10 }} padding="3rem var(--cp)">
        <Rates />
      </Box>

      <Box
        bg="#eee"
        py="6rem"
        px={{ lg: 'calc(var(--cp) + 10rem)', base: 5 }}
        textAlign="center"
      >
        <VStack
          p="0 5px"
          width="500px"
          maxW="100%"
          sx={{ margin: '0 auto !important' }}
        >
          <Heading size="lg" color="purple">
            Frequently Asked Questions (FAQs)
          </Heading>
          <Text fontWeight={500}>
            Our focus is always on finding the best people to work with. Our bar
            is high, but you look ready to take on the challenge.
          </Text>
        </VStack>

        <Box my={10}>
          <CurrenciesFAQ />
        </Box>

        <Text fontWeight={500}>
          How can we help?{' '}
          <ChakraLink to="/contact" href="/contact" as={Link}>
            Contact us
          </ChakraLink>
        </Text>
      </Box>
    </Layout>
  );
};

export default Currencies;
