import React, { useMemo } from 'react';
import { Text } from '@chakra-ui/react';
import { useCoins } from '../hooks';

export interface LivePriceProps {
  symbol: string;
}

const getColor = (change: number) => {
  if (change === 0) return 'black';
  return change > 0 ? 'rgb(22, 199, 132)' : 'crimson';
};

const LivePrice: React.FC<LivePriceProps> = ({ symbol }) => {
  const { data: coins, isFetching } = useCoins();

  const value = useMemo(() => {
    if (isFetching && !coins.length) return { color: 'black' };

    const coin = coins.find((coin: any) => coin.symbol === symbol);

    if (!coin) return {};

    const price = Number(coin?.priceUsd).toFixed(2);
    const color = getColor(coin.changePercent24Hr);

    return { price, color };
  }, [coins, isFetching, symbol]);

  return (
    <Text align="right" fontSize="sm" fontWeight="bold" color={value.color}>
      {!value.price ? 'Loading...' : <>&#36;{value.price}</>}
    </Text>
  );
};

export default LivePrice;
