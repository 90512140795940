import { Crypto } from './types';
import { Currency } from './components/product/item';

export const cleanDenominations = (
  denominations: Crypto['denominations'],
): Array<{ min: number; max: number; range: string; buy: string }> =>
  denominations.map((denomination) => {
    const { range } = denomination;
    const ranges = range.split('-').map((r) => parseInt(r, 10));

    let min: number;
    let max: number;

    if (ranges.length === 1) [min, max = Infinity] = ranges;
    else [min, max] = ranges;

    return { ...denomination, min, max };
  });

export const debounce = <F extends (...args: any) => any>(
  func: F,
  delay: number,
) => {
  let timeout: ReturnType<typeof setTimeout>;

  function deboundedFunc(...args: any) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  }

  return deboundedFunc as (...args: Parameters<F>) => ReturnType<F>;
};

export const separateByComma = (value: string) =>
  value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getPriceDiff = (price: number, lastPrice: number) => {
  const diff = Math.abs(price - lastPrice);
  const increased = price > lastPrice;

  return { diff, increased };
};

export const priceToEntities = (values: string[][]) =>
  values.map((value) => {
    const id = value.length - 1;
    const copyOfValue = value.filter((_, i) => i !== id);

    const priceObject = value[id].split(',').reduce((price, p) => {
      const [key, value] = p.split('-');
      return { ...price, [key.toLowerCase()]: value };
    }, {}) as Record<Currency, string>;

    return (copyOfValue as Array<string | Record<Currency, string>>).concat([
      priceObject,
    ]);
  });
