import React, { ReactElement, useCallback, useMemo } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
  useBreakpointValue,
  GridItem,
  Grid,
} from '@chakra-ui/react';
import { Column } from 'react-table';

import { Crypto as CryptoInterface } from '../../types';
import { useCoins, useMessageBird } from '../../hooks';
import DataTable from '../data-table';
import DataList from '../data-list';

type Rate = {
  buy: string;
  range: string;
  price: string;
  badge: ReactElement;
  change: ReactElement;
  action: ReactElement;
};

const columns: Column<Rate>[] = [
  {
    Header: 'Name',
    accessor: 'badge',
  },
  {
    Header: 'Price',
    accessor: 'price',
  },
  {
    Header: '24h',
    accessor: 'change',
  },
  {
    Header: 'Range',
    accessor: 'range',
  },
  {
    Header: 'Buy',
    accessor: 'buy',
  },
  {
    Header: '',
    accessor: 'action',
  },
];

export interface CryptoProps {
  crypto: CryptoInterface[];
}

export interface DataListProps extends Rate {}

const DataListHead: React.FC<DataListProps> = ({ badge, price, change }) => {
  const getPriceColor = useMemo(() => change.props.color, [change]);

  return (
    <Flex justify="space-between" align="center" mb={5}>
      {badge}
      <Text color={getPriceColor}>{price}</Text>
    </Flex>
  );
};

const DataListBody: React.FC<DataListProps> = ({ buy, range, action }) => (
  <VStack align="left" spacing={5}>
    <Grid gap={{ md: 10, sm: 5 }} templateColumns="1fr 1fr">
      <VStack as={GridItem} align="left" spacing={2}>
        <Heading size="sm" fontWeight={600}>
          Range
        </Heading>
        <Text>{range}</Text>
      </VStack>
      <VStack as={GridItem} align="left" spacing={2}>
        <Heading size="sm" fontWeight={600}>
          Buy
        </Heading>
        <Text>{buy}</Text>
      </VStack>
    </Grid>

    <Box>{action}</Box>
  </VStack>
);

const Crypto: React.FC<CryptoProps> = ({ crypto }) => {
  const { data: coins, isFetching } = useCoins();
  const messageBird = useMessageBird();
  const isMediumScreen = useBreakpointValue({
    md: true,
    lg: false,
    base: true,
  });

  const getRange = (denominations: CryptoInterface['denominations']) => {
    const lastIndex = denominations.length - 1;
    const { 0: first, [lastIndex]: last } = denominations;

    const [min] = first.range.split('-');
    const [max] = last.range.split('-');

    return {
      denomination: `$${min} - $${max}`,
      buy: `$${first.buy} - $${last.buy}`,
    };
  };

  const getChange = (change: number = 0) => {
    const increased = change > 0;
    const color = increased ? 'rgb(22, 199, 132)' : 'crimson';
    const percentage = `%${Number(change).toFixed(2)}`;

    return <Text color={color}>{percentage}</Text>;
  };

  const createAction = useCallback(
    (symbol: string) => () =>
      messageBird().startConversation(`Hello, I want to trade some ${symbol}.`),
    [messageBird],
  );

  const getAction = (symbol: string) => (
    <Button
      size="sm"
      color="purple"
      bg="aquamarine"
      fontWeight="bold"
      _hover={{ bg: 'aquamarine' }}
      onClick={createAction(symbol)}
    >
      Trade
    </Button>
  );

  const getName = ({
    icon,
    name,
    shortname,
  }: {
    icon: string;
    name: string;
    shortname: string;
  }) => (
    <HStack>
      {icon && <Image src={icon} alt={shortname} width="25px" />}
      <Text>{name}</Text>
      <Text>{shortname}</Text>
    </HStack>
  );

  const data = useMemo(() => {
    if (isFetching && !coins.length) return [];

    return crypto
      .map(({ shortname, name, denominations }) => {
        const coin = coins.find((coin: any) => coin.symbol === shortname);

        if (!coin) return null;

        const { denomination: range, buy } = getRange(denominations);
        const price = `$${Number(coin.priceUsd).toFixed(2)}`;
        const badge = getName({ shortname, name, icon: '' });
        const change = getChange(coin.changePercent24Hr);
        const action = getAction(name);

        return { price, range, buy, badge, change, action };
      })
      .filter(Boolean);
  }, [crypto, coins, isFetching]);

  return (
    <Box>
      {isMediumScreen ? (
        <DataList<Rate>
          data={data as Rate[]}
          Head={DataListHead}
          Body={DataListBody}
        />
      ) : (
        <DataTable columns={columns} data={data as Rate[]} />
      )}
    </Box>
  );
};

export default Crypto;
