import React from 'react';
import { SimpleGrid, VStack } from '@chakra-ui/react';

export interface DataListProps<Item = {}> {
  data: Item[];
  Head: React.FC<Item>;
  Body: React.FC<Item>;
}

const DataList = <Item extends {}>({
  data,
  Head,
  Body,
}: DataListProps<Item>) => (
  <SimpleGrid spacing={10} pt={0} p={10} columns={[1, 1, 2]}>
    {data.map((item, index) => {
      const key = `accordion-${index + 1}`;

      return (
        <VStack key={key} align="left" border="none" boxShadow="md" p={5}>
          <Head {...item} />
          <Body {...item} />
        </VStack>
      );
    })}
  </SimpleGrid>
);

export default DataList;
