import React, { useState, useCallback } from 'react';
import {
  VStack,
  FormControl,
  Input,
  Button,
  Text,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
} from '@chakra-ui/react';

import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { OnlineFund } from '../../types';
import { useMessageBird } from '../../hooks';
import { cleanDenominations } from '../../utils';

export interface OnlineFundsProps {
  funds: OnlineFund[];
}

const OnlineFunds: React.FC<OnlineFundsProps> = ({ funds }) => {
  const [fund, setFund] = useState<string>(funds[0].id);
  const [value, setValue] = useState<number>(0);
  const [inputError, setInputError] = useState<string>(undefined as any);
  const [result, setResult] = useState<string>(undefined as any);
  const messageBird = useMessageBird();

  const convert = useCallback(
    (units: number, fund: string) => {
      const f = funds.find((f) => f.id === fund);

      const variants =
        cleanDenominations(f?.variants as OnlineFund['variants']) || [];

      let v = variants.find(
        (variant) => units >= variant.min && units <= variant.max,
      );

      if (!v) {
        v = variants.find((variant) => units < variant.min);
      }

      const costPerUnit: number = parseInt(v?.buy || '0', 10);
      const value = costPerUnit * units;

      return value;
    },
    [funds],
  );

  const getFund = useCallback(
    (id: string) => funds.find((f) => f.id === id),
    [funds],
  );

  const isBelowMin = useCallback(
    (value: number) => {
      const { min } = cleanDenominations(
        getFund(fund)?.variants as OnlineFund['variants'],
      )[0];

      return { check: value > 0 && value < min, min };
    },
    [funds, fund, getFund],
  );

  const handleInputChange = useCallback(
    (event) => {
      const value = parseInt(event.target.value, 10) || 0;
      setValue(value);

      const amount = convert(value, fund);
      const isBelow = isBelowMin(value);

      setInputError(
        isBelow.check
          ? `Please enter an amount from $${isBelow.min}`
          : (undefined as any),
      );

      setResult(amount.toString());
    },
    [convert, fund, setResult, isBelowMin, setValue],
  );

  const handleTradeNow = useCallback(() => {
    if (!fund || isBelowMin(value).check) return;

    const { name } = getFund(fund) ?? {};
    const message = `I want to trade $${value} of ${name}.`;

    messageBird()?.startConversation(message);
  }, [messageBird, fund, funds, value, isBelowMin, getFund]);

  const handleMenuClick = (event: any) => {
    if (event.target.tagName === 'BUTTON') {
      setFund(event.target.dataset?.fund);
    }
  };

  const cleanedFunds = funds
    .map((fund) => {
      const copiedFund = fund;
      copiedFund.variants = copiedFund.variants.filter(
        (v) => Number(v.buy) > 0,
      );

      return copiedFund;
    })
    .filter((fund) => fund.variants.length > 0);

  const selectedFund = funds.find((f) => f.id === fund);

  return (
    <VStack width="100%" as="form" spacing={3} justify="start">
      <FormControl position="relative" id="fromSelector">
        {inputError && (
          <Text color="crimson" fontWeight="bold" fontSize="sm">
            {inputError}
          </Text>
        )}

        <InputGroup my={1} height="60px">
          <InputLeftElement
            height="60px"
            display="grid"
            placeItems="center"
            children={<Text fontSize="xl">&#36;</Text>}
          />
          <Input
            required
            size="xl"
            fontSize="2xl"
            type="number"
            placeholder="0"
            borderRadius="md"
            name="fromValue"
            onChange={handleInputChange}
            sx={{ paddingRight: '10px' }}
            isInvalid={Boolean(inputError)}
          />

          <InputRightAddon
            height="60px"
            children={
              <Menu>
                {({ isOpen }) => (
                  <>
                    <MenuButton
                      size="sm"
                      as={Button}
                      variant="unstyled"
                      textTransform="uppercase"
                      isActive={isOpen}
                      rightIcon={
                        isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />
                      }
                    >
                      {selectedFund?.name}
                    </MenuButton>
                    <MenuList
                      maxHeight="300px"
                      overflowY="scroll"
                      onClick={handleMenuClick}
                    >
                      {cleanedFunds.map((fund) => (
                        <MenuItem
                          key={fund.id}
                          data-fund={fund.id}
                          textTransform="capitalize"
                        >
                          {fund.name}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </>
                )}
              </Menu>
            }
          />
        </InputGroup>
      </FormControl>

      <FormControl position="relative" id="toSelector">
        <InputGroup my={1} height="60px">
          <InputLeftElement
            height="60px"
            display="grid"
            placeItems="center"
            children={<Text fontSize="xl">&#8358;</Text>}
          />
          <Input
            required
            readonly
            size="xl"
            type="number"
            name="toValue"
            fontSize="2xl"
            value={result}
            borderRadius="md"
            borderRight="none"
          />

          <InputRightAddon
            height="60px"
            borderLeft="none"
            bgColor="transparent"
            children={<Text fontWeight="medium">NGN</Text>}
          />
        </InputGroup>
      </FormControl>

      <Flex justifyContent="flex-end" width="100%">
        <Button
          color="purple"
          bg="aquamarine"
          alignSelf="start"
          borderColor="aquamarine"
          onClick={handleTradeNow}
          _hover={{ textDecoration: 'none' }}
        >
          Trade Now
        </Button>
      </Flex>
    </VStack>
  );
};

export default React.memo(OnlineFunds);
