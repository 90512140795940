import {
  Box,
  HStack,
  VStack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Heading,
  Image,
  useBreakpointValue,
  Flex,
  Button,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  GridItem,
  Grid,
  SimpleGrid,
  chakra,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react';
import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import emojiFlags from 'emoji-flags';
import Nanoclamp from 'nanoclamp';
import Fuse from 'fuse.js';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { Giftcard, GiftcardDenomination } from '../../types';
import { useMessageBird, useSearch } from '../../hooks';
import countries from '../../countries.json';
import ActionButton from '../action-button';
import DataList from '../data-list';
import Search from '../search';

export interface GiftcardsProps {
  giftcards: Giftcard[];
}

export type ActionMeta = Partial<Record<'country' | 'form' | 'range', string>>;

export interface DataListProps extends Giftcard {}

export const getCountry = (id: string) =>
  countries.find((country) =>
    ['name', 'iso2', 'iso3'].some(
      (key) =>
        id.toUpperCase() === (country as any)[key].toUpperCase() ||
        (country as any)[key].startsWith(id),
    ),
  );

export const getCountryFlag = (country: string) => {
  const countryCode = getCountry(country)?.iso2;
  const countryData = countryCode ? emojiFlags.countryCode(countryCode) : null;
  return countryData?.emoji;
};

const renderForm = (form: string, receipt: string) => (
  <VStack align="left" spacing={3}>
    <Text>{form}</Text>
    <HStack>
      {receipt
        .split(',')
        .filter(Boolean)
        .map((r) => (
          <Tag key={r}>{r.trim()}</Tag>
        ))}
    </HStack>
  </VStack>
);

const GiftcardID: React.FC<{
  name: string;
  icon?: string;
  description: string;
  maxWidth?: string;
  clamp?: boolean;
}> = ({ name, description: desc, icon, maxWidth = '180px', clamp = false }) => {
  const [iconSRC, setIconSRC] = useState<string | false>(false);
  const description = String(desc).trim();

  useEffect(() => {
    (async () => {
      try {
        const { default: iconSRC } = await import(
          `../../icons/giftcards/${icon}.svg`
        );
        setIconSRC(iconSRC);
      } catch {
        setIconSRC(false);
      }
    })();
  }, [icon]);

  return (
    <VStack align="left" maxWidth={maxWidth}>
      <HStack>
        {iconSRC && <Image src={iconSRC} alt={name} width={30} />}
        <Heading size="sm" textTransform="capitalize">
          {name}
        </Heading>
      </HStack>
      {description && (
        <Text fontWeight={400} fontSize="sm" mt={10} lineHeight={1.5}>
          {clamp && <Nanoclamp is="span" lines={2} text={description} />}
          {!clamp && String(description).trim()}
        </Text>
      )}
    </VStack>
  );
};

const formatRange = (range: string) => {
  if (range?.length < 1) return null;

  if (range?.indexOf('-') > -1) {
    const [from, to] = range.split('-');
    return `$${from.trim()} - $${to.trim()}`;
  }

  if (range?.indexOf(',') > -1) {
    return range
      .split(',')
      .filter(Boolean)
      .map((r) => `$${r}`)
      .join(', ');
  }

  return Boolean(range) && `$${range}`;
};

const DataListHead: React.FC<DataListProps> = ({ name, description, icon }) => (
  <GiftcardID
    clamp
    name={name}
    icon={icon}
    maxWidth="100%"
    description={description}
  />
);

const DataListBody: React.FC<DataListProps> = ({
  name,
  denominations,
  description,
}) => {
  const messageBird = useMessageBird();
  const [search, setSearch] = useState<string>('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const createAction = useCallback(
    (name: string) => (denomination: GiftcardDenomination) => () => {
      const message = `
        Hello, I want to trade some ${name}.

        Country - ${denomination?.country}
        Form - ${denomination?.form}
        Range - ${formatRange(denomination?.range || '')}
      `;

      messageBird().startConversation(message);
    },
    [messageBird],
  );

  const handleSearchInput = (event: any) => {
    setSearch(event.target.value);
  };

  const handleResetSearch = () => {
    setSearch('');
  };

  const countries = [
    ...new Set(denominations.map((denomination) => denomination.country)),
  ];

  const filteredDenominations = useMemo(() => {
    if (!search) return denominations;

    const results = new Fuse(denominations, {
      keys: ['country'],
      threshold: 0.2,
    }).search(search);

    return results.map(({ item }) => item);
  }, [search, denominations]);

  return (
    <Box>
      {countries.length > 0 && (
        <Flex flexDir="row" flexWrap="wrap">
          {countries.map((country) => (
            <Tag mt={1} mr={1} key={country} textTransform="capitalize">
              {country}
            </Tag>
          ))}
        </Flex>
      )}

      <Button
        mt={5}
        size="md"
        color="purple"
        bg="aquamarine"
        onClick={onOpen}
        fontWeight="bold"
        _hover={{ bg: 'aquamarine' }}
      >
        View Rates
      </Button>

      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen} isFullHeight>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <Flex justifyContent="space-between" alignItems="center">
              <Heading p={0} m={0} size="d">
                {name}
              </Heading>
              <DrawerCloseButton position="static" />
            </Flex>

            <Text fontWeight={300} fontSize="md" mt={2}>
              {description}
            </Text>

            <Box px={{ md: 10, sm: 0 }} mt={5}>
              <InputGroup mt={10}>
                <InputLeftElement children={<SearchIcon color="gray.300" />} />
                <Input
                  name="search"
                  value={search}
                  onInput={handleSearchInput}
                  disabled={!denominations.length}
                  placeholder="Search variants..."
                />
                {!!search && (
                  <InputRightElement
                    children={
                      <IconButton
                        size="xs"
                        border="none"
                        background="transparent"
                        aria-label="Reset Search"
                        onClick={handleResetSearch}
                        icon={<CloseIcon color="gray.300" />}
                      />
                    }
                  />
                )}
              </InputGroup>
            </Box>
          </DrawerHeader>
          <DrawerBody pb={10}>
            {!search && !denominations.length && (
              <Box display="grid" height="200px" placeItems="center">
                <Text textAlign="center">
                  Sorry, we are not trading {name} at this time.
                </Text>
              </Box>
            )}

            {search && !filteredDenominations.length && (
              <Box display="grid" height="200px" placeItems="center">
                <Text textAlign="center">No search results.</Text>
              </Box>
            )}

            {Boolean(filteredDenominations.length) && (
              <SimpleGrid
                spacing={5}
                px={{ md: 10, sm: 0 }}
                columns={[1, 1, 2]}
              >
                {filteredDenominations.map((denomination, index) => {
                  const { form, country, buy, range, receipt } = denomination;
                  const countryFlag = getCountryFlag(country);

                  return (
                    <Grid
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${country}-${index}`}
                      gap={{ md: 10, sm: 5 }}
                      templateColumns="1fr 1fr"
                      minHeight="150px"
                      sx={{ marginTop: '20px !important' }}
                      border="none"
                      boxShadow="md"
                      p={5}
                    >
                      <GridItem>
                        <Flex mb={1}>
                          {countryFlag && (
                            <chakra.span mr={2} size="md">
                              {countryFlag}
                            </chakra.span>
                          )}
                          <Text fontWeight="semibold" fontSize="sm">
                            {country}
                          </Text>
                        </Flex>

                        <Heading size="md">{formatRange(range)}</Heading>

                        <Flex flexDir="row" flexWrap="wrap" mt={5}>
                          <Tag mr={1} textTransform="capitalize">
                            {form}
                          </Tag>
                          {receipt && receipt !== 'No Receipt' && (
                            <>
                              {receipt
                                .split(',')
                                .filter(Boolean)
                                .map((r) => (
                                  <Tag key={r} textTransform="capitalize">
                                    {r.trim()}
                                  </Tag>
                                ))}
                            </>
                          )}
                        </Flex>
                      </GridItem>
                      <Flex
                        as={GridItem}
                        height="100%"
                        flexDirection="column"
                        textAlign="right"
                        position="relative"
                      >
                        <Flex textAlign="right" flexDirection="row-reverse">
                          <Heading size="lg" fontWeight="bold">
                            {buy}
                          </Heading>
                          <Text size="5px" as="span" fontWeight="semibold">
                            $&nbsp;
                          </Text>
                        </Flex>
                        <Box position="absolute" right={0} bottom={0}>
                          <ActionButton<GiftcardDenomination>
                            size="sm"
                            text="Trade Now"
                            item={denomination}
                            creator={createAction(name)}
                          />
                        </Box>
                      </Flex>
                    </Grid>
                  );
                })}
              </SimpleGrid>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

const GiftCards: React.FC<GiftcardsProps> = ({ giftcards }) => {
  const { query, results, handleOnInput } = useSearch<Giftcard>(giftcards, [
    'name',
    'description',
    'denominations.form',
    'denominations.country',
  ]);
  const messageBird = useMessageBird();
  const isMediumScreen = useBreakpointValue({
    md: true,
    lg: false,
    base: true,
  });

  const createAction = useCallback(
    (name: string) => (denomination: GiftcardDenomination) => () => {
      const message = `
        Hello, I want to trade some ${name}.

        Country - ${denomination?.country}
        Form - ${denomination?.form}
        Range - ${formatRange(denomination?.range || '')}
      `;

      messageBird().startConversation(message);
    },
    [messageBird],
  );

  const renderCard = ({ name, denominations, description, icon }: Giftcard) => (
    <>
      {denominations.map((denomination, index) => {
        const { form, buy, country, range, receipt } = denomination;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Tr key={denomination.country + index}>
            {index === 0 && (
              <Th
                left={0}
                bg="white"
                zIndex="base"
                position="sticky"
                fontWeight="bold"
                verticalAlign="top"
                textTransform="capitalize"
                rowSpan={denominations.length}
              >
                <GiftcardID name={name} description={description} icon={icon} />
              </Th>
            )}
            <Td textTransform="capitalize">
              {renderForm(form, receipt || '')}
            </Td>
            <Td textTransform="capitalize">{country}</Td>
            <Td textTransform="capitalize">{formatRange(range)}</Td>
            <Td textTransform="capitalize">{buy && `${buy}/$`}</Td>
            <Td bg="white" right={0} zIndex="base" position="sticky">
              <ActionButton<GiftcardDenomination>
                size="md"
                item={denomination}
                creator={createAction(name)}
              />
            </Td>
          </Tr>
        );
      })}
    </>
  );

  const data = query ? results : giftcards;

  return (
    <Box>
      <Search query={query} onInput={handleOnInput} />

      {isMediumScreen && (
        <DataList<DataListProps>
          data={data}
          Head={DataListHead}
          Body={DataListBody}
        />
      )}

      {!isMediumScreen && (
        <Table>
          <Thead>
            <Tr>
              <Th left={0} bg="white" zIndex="base" position="sticky">
                Name
              </Th>
              <Th>Form</Th>
              <Th>Country</Th>
              <Th>Range</Th>
              <Th>Buy</Th>
              <Th />
            </Tr>
          </Thead>

          <Tbody>
            {data.map((giftcard) => (
              <Fragment key={giftcard.id}>{renderCard(giftcard)}</Fragment>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
};

export default GiftCards;
